<template>
  <a-spin :spinning="spinning">
      <div class="modal-header">
        <div class="modal-title">
          <span>{{ l("发放明细") }}</span>
        </div>
      </div>
      <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :columns="columns"
        :scroll="{ x: 1900 }"
        size="small"
        :hideRowSelection="false"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="false"
        :hide-row-selection="false"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row :gutter="8">
          <a-col class="gutter-row" :span="4">
            <a-input
              v-model="SearchDto.PersonName"
              :placeholder="l('ReceiverName')"
              style="width: 98%"
              allowClear
            ></a-input>
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-select v-model="SearchDto.Status" style="width: 98%" :placeholder='l("PleaseSelectStatus")'>
              <a-select-option
                v-for="item in SalaryStatus"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-col>

          <a-col class="gutter-row" :span="4">
            <a-button type="primary" @click="search">
              {{ l("Search") }}
            </a-button>
            <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
          </a-col>
          <a-col class="gutter-row" :span="12" align="right">
            <a-button v-if="showReSalary" type="primary" @click="ReSalary()">
              <a-icon type="plus" />
              {{ l("ReSalaryOperation") }}
            </a-button>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="8" class="btn--container">
          <a-col class="gutter-row" :span="24">
            <a-button v-if="showReSalary" type="primary" @click="ReSalary()">
              <a-icon type="plus" />
              {{ l("ReSalaryOperation") }}
            </a-button>
          </a-col>
        </a-row> -->
      </ele-table>
      <a-modal
        :title="l('HintInformation')"
        :visible="showSettlementInfo"
        @cancel="hideSettlementInfo"
        :width="805"
      >
        <a-row :gutter="24">
          <a-col span="24">
            <a-table
              bordered
              :data-source="settlementErrorResult"
              :pagination="false"
            >
              <a-table-column
                key="realName"
                :title="l('NameSurname')"
                data-index="realName"
                :ellipsis="true"
                width="75px"
              ></a-table-column>
              <a-table-column
                key="phoneNumber"
                :title="l('ProjectUserPhone')"
                data-index="phoneNumber"
                :ellipsis="true"
                width="150px"
              ></a-table-column>
              <a-table-column
                key="idCard"
                :title="l('ProjectUserIdCard')"
                data-index="idCard"
                :ellipsis="true"
                width="190px"
              ></a-table-column>
              <a-table-column
                key="thisTimeAmount"
                :title="l('SettlementAmount')"
                data-index="thisTimeAmount"
                :ellipsis="true"
                width="90px"
              ></a-table-column>
              <a-table-column
                key="remarks"
                :title="l('MessageError')"
                data-index="remarks"
                :ellipsis="true"
                width="250px"
              ></a-table-column>
            </a-table>
          </a-col>
        </a-row>
        <template slot="footer">
          <a-button key="back" @click="hideSettlementInfo">{{
            l("Close")
          }}</a-button>
        </template>
      </a-modal>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";

export default {
  name: "salaryDetailModule",
  mixins: [ModalComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        Status: undefined,
        PersonName: "",
        SkipCount: 0,
        MaxResultCount: 50,
      },
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [0, 1],
      selectedRowKeys: [],
      selectedRows: [],
      showReSalary: false,
      tableData: [],
      SalaryStatus: [
        // { label: this.l("PleaseSelectStatus"), value: -1 },
        { label: this.l("WaitForSend"), value: 0 },
        { label: this.l("SalarySuccess"), value: 1 },
        { label: this.l("SalaryFailed"), value: 2 },
        { label: this.l("ReSalaried"), value: 3 },
        { label: this.l("已驳回"), value: 4 },
        { label: this.l("发放中"), value: 5 },
        { label: this.l("已作废"), value: 6 },
      ],
      columns: [
        {
          title: this.l("SalaryNo"),
          dataIndex: "number",
          align: "center",
          fixed: "left",
          width: 150,
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.l("ReceiverName"),
          dataIndex: "userName",
          width: 120,
          fixed: "left",
          align: "center",
        },
        {
          title: this.l("PayAmount"),
          dataIndex: "amount",
          width: 120,
          fixed: "left",
          align: "center",
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: this.l("BankAccountNo"),
          dataIndex: "bankNum",
          width: 210,
          ellipsis: true,
          align: "center",
        },
        {
          title: this.l("BankName"),
          dataIndex: "bankName",
          width: 210,
          ellipsis: true,
          align: "center",
        },
        {
          title: this.l("ProjectUserIdCard"),
          dataIndex: "idCard",
          width: 210,
          ellipsis: true,
          align: "center",
        },
        {
          title: this.l("ProjectUserPhone"),
          dataIndex: "phone",
          width: 130,
          align: "center",
        },
        {
          title: this.l("state"),
          dataIndex: "statusName",
          align: "center",
        },
        {
          title: this.l("reason"),
          dataIndex: "refundReason",
          align: "center",
        },
        {
          title: this.l("PurchaseTime"),
          dataIndex: "payTime",
          align: "center",
          width: 170,
          customRender: (text) => {
            if (text) {
              return text.replaceAll("T", " ").split(".")[0];
            } else {
              return ''
            }
          },
        },
        // {
        //   title: "提现订单号",
        //   dataIndex: "withdrawOrderNo",
        //   align: "center",
        // },
        {
          title: this.l("ProjectRemark"),
          dataIndex: "remark",
          align: "center",
        },
        {
          title: this.l("SinglePrice"),
          dataIndex: "pieceWage",
          align: "center",
        },
        {
          title: this.l("Reward"),
          dataIndex: "bounty",
          align: "center",
        },
        {
          title: this.l("Deductions"),
          dataIndex: "deduction",
          align: "center",
        },
      ],
      showSettlementInfo: false,
      settlementErrorResult: [],
    };
  },
  methods: {
    /**
     * ���·���
     * */
    ReSalary() {
      if (!this.selectedRows || this.selectedRows.length <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return false;
      }
      let ids = [];
      console.log("this.selectedRows:", this.selectedRows);
      for (let i = 0; i < this.selectedRows.length; i++) {
        let item = this.selectedRows[i];
        if (item.status != 2) {
          abp.message.warn(this.l("ReSalaryError"));
          return false;
        }
        ids.push(item.id);
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/ReGenerateSalary",
        method: "POST",
        data: { salaryBatchId: this.id, salaryPayRecordIds: ids },
      };
      this.$api
        .request(options)
        .then((res) => {
          let data = res.data;
          console.log(res)
          if (data && data.length > 0) {
           
            this.showSettlementInfo = true;
            this.settlementErrorResult.splice(
              0,
              this.settlementErrorResult.length
            );
            this.settlementErrorResult = data;

            this.showBatchSalary = false;
            
          } else {
            abp.message.success(this.l("SuccessfullyOperation"));
            this.close()
          }
          // this.getData();
          // if (res.status == 200) {
          //   abp.message.success(this.l("SuccessfullyOperation"));
          //   this.getData();
          // } else {
          //   abp.message.error(res.statusText);
          // }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    search() {
      this.pageNumber = 1
      this.SearchDto.SkipCount = 0
      this.getData()
    },
    /**
     * ��ȡ���� */
    getData() {
      if (this.SearchDto.ProjectId == "") {
        return false;
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/GetSalaryPayRecordsPaged",
        method: "GET",
        params: {
          realName: this.SearchDto.PersonName,
          payStatus: this.SearchDto.Status ? this.SearchDto.Status : null,
          salaryBatchId: this.id,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            // this.totalPages =
            //   (data.totalCount + this.SearchDto.MaxResultCount - 1) /
            //   this.SearchDto.MaxResultCount;
               this.totalPages = Math.ceil(data.totalCount / this.SearchDto.MaxResultCount);
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              let counts = item.salaryPayStatusCounts;
              let success = 0,
                failed = 0,
                pending = 0,
                resalary = 0;
              if (counts && counts.length > 0) {
                let sus = counts.filter((it) => it.status == 1);
                success = sus ? sus.length : 0;
                let fail = counts.filter((it) => it.status == 2);
                failed = fail ? fail.length : 0;
                let re = counts.filter((it) => it.status == 3);
                resalary = re ? re.length : 0;
                pending = item.salaryTotal - success;
              }
              const pt = item.payTime ? item.payTime.split('T') : null
              return {
                ...item,
                success: success,
                failed: failed,
                pending: pending,
                resalary: resalary,
                payTime: pt ? `${pt[0]} ${pt[1].split('.')[0]}`  : '',
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    hideBatchReject() {
      this.showBatchReject = false;
    },
    clearAndGetData() {
      this.SearchDto.PersonName = "";
      this.SearchDto.Status = undefined;
      this.SearchDto.skipCount = 0
      this.getData();
    },
    hideSettlementInfo() {
      this.showSettlementInfo = false;
    },
  },
  created() {
    this.fullData();
    if (this.showrs && this.showrs == 1) {
      this.showReSalary = true;
    }
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
