<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :columns="columns"
      :scroll="{ x: 0 }"
      :hideRowSelection="true"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitSortData="updateSortData"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="5">
          <a-select
            v-model="SearchDto.PayCompanyId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            placeholder="签约公司"
            allowClear
          >
            <a-select-option
              v-for="item in PayCompanys"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-select
            v-model="SearchDto.CustomerId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            placeholder="客户名称"
            allow-clear
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-month-picker
            v-model="SearchDto.BatchMonth"
            placeholder="开票月份，默认当月"
            valueFormat="YYYY-MM"
            style="width: 100%"
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-button
            type="primary"
            @click="searchData"
            v-if="isGranted('Pages.InvoicingPermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" style="text-align: right" :span="7">
          <a-button-group>
            <a-button
              v-if="isGranted('Pages.InvoicingPermissions.Export')"
              @click="exportToExcel()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import { CommonServiceProxy } from "@/shared/common-service";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { fileDownloadService } from "@/shared/utils";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        PersonName: "",
        BatchMonth: "",
        Status: -1,
        CustomerId: undefined,
        PayCompanyId: undefined,
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 50,
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      SalaryStatus: [
        { label: this.l("PleaseSelectStatus"), value: -1 },
        { label: this.l("WaitForSend"), value: 0 },
        { label: this.l("SalarySuccess"), value: 1 },
        { label: this.l("SalaryFailed"), value: 2 },
        { label: this.l("ReSalaried"), value: 3 },
      ],
      columns: [],
      PayCompanys: [], // { value: "", label: "签约公司" }
      Customers: [], // { value: "", label: "客户名称" }
    };
  },
  methods: {
    /**
     * ������
     * @param input
     * @param option
     */
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: "签约公司",
          dataIndex: "organizationUnitName",
          align: "center",
          ellipsis: true,
        },
        {
          title: "客户名称",
          dataIndex: "clientName",
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "customerName" },
        },
        {
          title: "开票月份",
          dataIndex: "billMonth",
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: "grantMouth" },
        },
        {
          title: "总开票金额/月",
          dataIndex: "billAmount",
          align: "center",
          ellipsis: true,
          customRender: (text) => {
            return `￥${text}`;
          },
        },
        {
          title: "服务人数",
          dataIndex: "peopleServedCount",
          align: "center",
          ellipsis: true,
        },
      ];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * ��ȡ�ͻ�
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     * ��ȡ���� */
    getData() {
      if (this.SearchDto.ProjectId == "") {
        return false;
      }
      this.spinning = true;
      let month = this.SearchDto.BatchMonth;
      console.info(month);
      let options = {
        url: this.$apiUrl + "/api/services/app/Bill/GetInvoicingSummaryPaged",
        method: "GET",
        params: {
          realName: this.SearchDto.PersonName,
          billMonth: this.SearchDto.BatchMonth,
          status: this.SearchDto.Status == -1 ? null : this.SearchDto.Status,
          clientId:
            this.SearchDto.CustomerId == "" ? null : this.SearchDto.CustomerId,
          signCompanyId:
            this.SearchDto.PayCompanyId == ""
              ? null
              : this.SearchDto.PayCompanyId,
          sorting: this.SearchDto.Sorting == "" ? null : this.SearchDto.Sorting,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
                billAmount: "￥" + item.billAmount,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Bill/GetToExcelFile",
          params: {
            realName: this.SearchDto.PersonName,
            billMonth: this.SearchDto.BatchMonth,
            status: this.SearchDto.Status == -1 ? null : this.SearchDto.Status,
            clientId:
              this.SearchDto.CustomerId == ""
                ? null
                : this.SearchDto.CustomerId,
            signCompanyId:
              this.SearchDto.PayCompanyId == ""
                ? null
                : this.SearchDto.PayCompanyId,
            sorting:
              this.SearchDto.Sorting == "" ? null : this.SearchDto.Sorting,
            skipCount: this.SearchDto.SkipCount,
            maxResultCount: this.SearchDto.MaxResultCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.SearchDto.PersonName = "";
      this.SearchDto.BatchMonth = "";
      this.SearchDto.Status = -1;
      this.SearchDto.CustomerId = undefined;
      this.SearchDto.PayCompanyId = undefined;
      this.SearchDto.Sorting = "";
      this.pageNumber = 1;
      this.getData();
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getAllPayCompany();
    this.getAllCutomer();
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
